<template>
  <CRow>
    <CCol col="12" lg="8">
      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Create Report
        </CCardHeader>
        <CCardBody>
            <CCardBody class="p-4">
              <CForm>
              <CAlert
                :show.sync="reportCreated"
                closeButton
                color="success">
                Report for customer {{ customerName }} created          
              </CAlert>
              <CAlert
                :show.sync="reportFailedMsg"
                closeButton
                color="danger"
                fade>
                   Creating report for customer {{ customerName }} failed
              </CAlert>              
            <CInput
              label="Report Name"
              placeholder="Report Name"
              v-model="reportName"
              valid-feedback="Report name is valid."
              invalid-feedback="Minimum length is 4 and only characters and spaces."
              :is-valid="validator"
              :disabled="inputDisabled"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>               
            <template>
              <div>
                <label class="typo__label">Customer</label>
                <multiselect 
                  v-model="selectedCustomer"
                  :options="customers" 
                  :searchable="true" 
                  :close-on-select="true"   
                  label="customer_name"               
                  placeholder="Pick or search a customer"
                  :disabled="inputDisabled"
                >                    
                </multiselect>
              </div>
              </template>
              <!-- <template>
                <div>
                  <label class="typo__label">Remediation Items</label>
                  <multiselect
                    :value="selectedRemediationItems"
                    :options="remediationItems"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="false"
                    :multiple="true"
                    label="name" 
                    :hideSelected="true"
                    @select="selectRemediationItem"
                    @remove="removeRemediationItem"
                    group-label="lense" 
                    group-values="data"
                    :group-select="true" 
                    :disabled="inputDisabled"
                  >
                   </multiselect>
                </div>
              </template>    -->
              <template>
                <div>
                  <label class="typo__label">Workload</label>
                  <multiselect
                    v-model="selectedWorkload"
                    :options="workloads"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="true"
                    :loading="workloadsLoading"
                    :multiple="false"
                    label="label" 
                    :hideSelected="false"
                    placeholder="Pick a workload"
                  >
                  </multiselect>
                </div>
              </template>  

              <CSelect
                label="Status"
                :options="statusList"                
                disabled
                >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CSelect>
              </CForm>
            </CCardBody>          
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton v-on:click="openReport();" color="success" class="float-right col-sm-4" v-show="showOpenReport" >Open Report</CButton>
          <CButton v-on:click="createReport();" color="success"  class="float-right col-sm-4" :disabled="createDisabled" v-show="!inputDisabled">Create new Report</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import Multiselect from 'vue-multiselect'
import ReportService from '@/services/report.services';
import CustomerService from '@/services/customer.services';
import WorkloadService from '@/services/workload.services';
import LenseService from '@/services/lense.services';
import ItemService from '@/services/item.services';


export default {
  components: {
    Multiselect
  },  
  data() {
    return {
      selectedCustomer: null,
      report: {},
      statusList: [],
      workloads: [],
      customers: [],
      lenses: {},
      workloadsLoading: true,
      customerName: "",
      reportName: "",
      selectedWorkload: null,
      selectedRemediationItems: [],
      remediationItems: [],
      reportCreated: false,
      inputDisabled: false,
      nextDisabled: true,
      createDisabled: false,
      workloadLoading: true,
      reportFailedMsg: false,
      showOpenReport: false
    };
  },

  methods: {
    validator (val) {
        var valid = false;
        
        const regex = new RegExp('^([a-zA-Z0-9-_\\.&!#=()\\s]){4,}$');
        valid = val ? regex.test(val)  : false;
        
        this.createCisabled = !valid;

        return valid
    },     
    goBack() {
      this.$router.push({path: '/reports'})
    },  
    openReport() {
      this.$router.push({path: '/reports/'+this.showOpenReportId})
    },           
    newReport() {
        this.selectedCustomer = null;
        this.selectedWorkload = null;
        this.selectedRemediationItems = [];

        this.reportCreated = false;
        this.inputDisabled = false;
        this.nextDisabled = true;
    },    
    selectRemediationItem(selectedOption, id) {
      var tmp = {}
      tmp['id'] = selectedOption.id
      tmp['name'] = selectedOption.name
      tmp['lense'] = selectedOption.lense
      this.selectedRemediationItems.push(tmp)
    },
    removeRemediationItem(removedOption, id) {
      const index = this.selectedRemediationItems.indexOf(removedOption);
      if (index > -1) {
         this.selectedRemediationItems.splice(index, 1);
      }
    },
    async createReport() {
      this.customerName = this.selectedCustomer.customer_name;
      
      this.showAlert()

      var tmp_remediation_items = []
      // for (var key in this.selectedRemediationItems) {
      //   tmp_remediation_items.push(this.selectedRemediationItems[key].id)
      // }          

      ReportService.createReport(
        this.reportName, 
        this.selectedCustomer.customer_id, 
        this.selectedWorkload.id, 
        this.statusList[0], 
        tmp_remediation_items
        ).then(
          response => { 
            this.reportCreated = true;
            this.inputDisabled = true;
            this.nextDisabled = false;
            this.showOpenReport = true;
            this.showOpenReportId = response.report_id;
          },
          error => {   
            this.reportFailedMsg();
            console.log(error);
          }      
      )
    },             
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },           
  },
  created() { },
  async mounted() { 
    await ReportService.getReportStatusList().then(
      response => { 
        this.statusList = response;
      },
      error => {   
        console.log(error)   
      }  
    )
    await WorkloadService.getWorkloads().then(
      response => { 
        var tmp_workloads = response;
        for (var key in tmp_workloads) {
          var tmp = {}
          tmp['id'] = tmp_workloads[key].WorkloadId
          tmp['label'] = tmp_workloads[key].WorkloadName
          this.workloads.push(tmp)
        };
        this.workloadsLoading = false;
      },
      error => {   
        console.log(error)   
      }      
    ) 
    await LenseService.getLenses().then(
      response => { 
        this.lenses = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    await  CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
      },
      error => {   
        console.log(error)   
      }      
    )
  await  ItemService.getItems().then(
      response => { 
        
        for (var key in response){
          
          var tmp = {}          
          tmp["lense"] = this.lenses[response[key].item_lense]
          tmp['data'] = []

          const index = this.remediationItems.findIndex(tmp => tmp.lense === this.lenses[response[key].item_lense]); 
        
          if (index > -1) {
            this.remediationItems[index]['data'].push( {"id": response[key].item_id, "name": response[key].item_title} )
          } else {
            tmp['data'].push( {"id": response[key].item_id, "name": response[key].item_title } )
            this.remediationItems.push(tmp)
          }                
        }
      },
      error => {   
        console.log(error)   
      }      
    ) 
  },  

  watch: { },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>